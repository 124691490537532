import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import getDate from 'date-fns/getDate';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import isWeekend from 'date-fns/isWeekend';
import isWithinInterval from 'date-fns/isWithinInterval';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import differenceInDays from 'date-fns/differenceInDays';
import isValid from 'date-fns/isValid';
import { getWeekdayNames } from 'utils/common';
import { dateFormat } from 'lib/DateFormat';
import { isEmpty, map } from 'lodash';

type TRange = {
    id?: number,
    time_off_day: Date,
    time_off_hour: string
};

const MAX_RANGE_YEAR_DAYS = 365;

const gapDates = (daysOfInterval: TRange[]) => {
    let firstDate = new Date(daysOfInterval[0].time_off_day);
    let lastDate = new Date(daysOfInterval.at(-1)?.time_off_day as Date) ?? 0;

    const startOfWeekDate = startOfWeek(firstDate, {weekStartsOn: 1});
    const endOfWeekDate = endOfWeek(lastDate, {weekStartsOn: 1});
    
    const gapToStart = differenceInCalendarDays(firstDate, startOfWeekDate)
    const gapToEnd = differenceInCalendarDays(endOfWeekDate, lastDate);

    const gapToStartDates = [];
    for (let i = 0; i < gapToStart; i++) {
        gapToStartDates.unshift(subDays(firstDate, i + 1));
    };

    const gapToEndDates = [];
    for (let i = 0; i < gapToEnd; i++) {
        gapToEndDates.push(addDays(lastDate, i + 1));
    };

    return [
        ...gapToStartDates.map(date => {
            return {
                time_off_day: date,
                time_off_hour: ''
            };
        }),
        ...daysOfInterval,
        ...gapToEndDates.map(date => {
            return {
                time_off_day: date,
                time_off_hour: ''
            };
        })
    ];
};

const getDateHourRangeFromTwoDate = (startDate: Date, endDate: Date, timeOffHours: any) => {
    const daysOfInterval = eachDayOfInterval({ start: startDate, end: endDate });

    const daysOfIntervalWithHour = daysOfInterval.map(date => {
        const record = timeOffHours.find((item: { time_off_day: string }) => {
                let elDate = new Date(item.time_off_day);
                return date == new Date(elDate);
            }
        )

        return {
            time_off_day: date,
            time_off_hour: record ? record.time_off_hour : ''
        };
    });

    const addedGapDaysOfInterval = gapDates(daysOfIntervalWithHour);

    return addedGapDaysOfInterval;
};

const renderHeader = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
        if (getYear(startDate) === getYear(endDate) && getMonth(startDate) === getMonth(endDate)) return dateFormat(startDate, 'longMonthAndYear');
        return <>{dateFormat(startDate, 'longMonthAndYear')} - {dateFormat(new Date(endDate.setHours(4, 0, 0, 0)), 'longMonthAndYear')}</>
    }
};

export default function PartialDate({ timeOffHours, mode = 'dynamic' }: any) {
    const weekdays = getWeekdayNames();
    
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [dates, setDates] = useState<any>([]);
    const [isReady, setIsReady] = useState<any>(false);
    const [originalDates, setOriginalDates] = useState<any>([]);


    useEffect(() => {
        if (!isEmpty(timeOffHours)) {
            
            let firstRecord = timeOffHours[0].time_off_day;
            let lastRecord = timeOffHours[timeOffHours.length - 1].time_off_day;

            let stDate = new Date(firstRecord);
            let enDate = new Date(lastRecord);
            const modifedStartDate = new Date(stDate.setHours(0, 0, 0, 0));
            const modifedEndDate = new Date(enDate.setHours(0, 0, 0, 0));

            setStartDate(modifedStartDate);
            setEndDate(modifedEndDate);

            const convertedDates = map(timeOffHours, (item: any) => {
                let itemDate = new Date(item.time_off_day);
                return {
                    ...item,
                    time_off_day: new Date(itemDate.setHours(0, 0, 0, 0))
                    
                }
            });
            setDates(convertedDates);

            const isReadyToRenderCalendar = mode === 'dynamic' && (isValid(modifedStartDate) && isValid(modifedEndDate)) && !isAfter(modifedStartDate, modifedEndDate) && !(differenceInDays(modifedEndDate, modifedStartDate) >= MAX_RANGE_YEAR_DAYS);
            setIsReady(isReadyToRenderCalendar);
        }
    }, [timeOffHours])

    
    useEffect(() => {
        if (!!startDate && !!endDate && isReady) {
            const daysOfIntervalWithHour = getDateHourRangeFromTwoDate(startDate, endDate, timeOffHours);
            const mergedArray = daysOfIntervalWithHour.map((elem) => {
                const isDateWeekend = isWeekend(elem.time_off_day);
                const match = dates.find((elem2: any) => isEqual(elem2.time_off_day, elem.time_off_day) && isWithinInterval(elem.time_off_day, { start: startDate, end: endDate }));

                return match ? match : { ...elem, time_off_hour: isDateWeekend ? '' : elem.time_off_hour };
            });
            setOriginalDates(mergedArray);
        };
    }, [startDate, endDate, dates, timeOffHours]);

    return (
        <Container>
            <CalendarHeaderContainer>{renderHeader(startDate, endDate)}</CalendarHeaderContainer>
            <table>
                <thead>
                    <tr>
                        {weekdays.map((day, i) => (
                            <CustomTh key={i}>{day}</CustomTh>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {(mode === 'dynamic' ? originalDates : gapDates(originalDates)).map((_: any, i: any) => {
                        if (i % 7 === 0) {
                            return (
                                <Fragment key={i}>
                                    <CustomTr>
                                        {(mode !== 'dynamic' ? originalDates : gapDates(originalDates)).slice(i, i + 7).map((item: any, j: any) => {
                                            return <td key={j}>{dateFormat(item?.time_off_day, 'shortMonth')} {getDate(new Date(item?.time_off_day))}</td>;
                                        })}
                                    </CustomTr>
                                    <CustomInputTr>
                                        {(mode !== 'dynamic' ? originalDates : gapDates(originalDates)).slice(i, i + 7).map((item: any, j: any) => {
                                            if (!isWithinInterval(item.time_off_day, { start: startDate, end: endDate }) && mode === 'dynamic') {
                                                return <td key={j}><input disabled /></td>;
                                            };
                                            return (
                                                <td key={j}>
                                                        <NumericFormat
                                                            disabled={true}
                                                            decimalSeparator="."
                                                            decimalScale={2}
                                                            valueIsNumericString
                                                            value={item.time_off_hour === '0' ? '' : +item.time_off_hour % 1 === 0 ? parseInt(item.time_off_hour) : item.time_off_hour}
                                                            isAllowed={(values) => {
                                                                const { value } = values;
                                                                return +value <= 24;
                                                            }}
                                                            placeholder={''}
                                                        />
                                                </td>
                                            );
                                        })}
                                    </CustomInputTr>
                                </Fragment>
                            );
                        };
                        return null;
                    })}
                </tbody>
            </table>
        </Container>
    )
};

const Container = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
    }

    & tr:last-child td:first-child {
        & > input {
            border-bottom-left-radius: 4px;
        };
    }
            
    & tr:last-child td:last-child {
        & > input {
            border-bottom-right-radius: 4px;
        };
    }
`;

const CalendarHeaderContainer = styled.div`
    display: flex; 
    flex: 1;
    justify-content: center;
    background-color: #172B37;
    color: #FFF;
    border-radius: 4px 4px 0 0;
    padding: 4px;
`;

const CustomTh = styled.th`
    border-inline: 1px solid #ddd;
    color: #00101A;
    font-size: 10px;
    padding: 5px;
    text-align: center;
    background-color: #FFF;
`;

const CustomTr = styled.tr`
    background-color: #E8F4EE;
    & > td {
        border-inline: 1px solid #ddd;
        color: #00101A;
        font-size: 11px;
        padding: 5px;
        text-align: center;
    }
`;

const CustomInputTr = styled.tr`
    & > td {
        & > input {
            width: 100%;
            height: 50px;
            background-color: #FFF;
            border: 0.5px solid #ddd;
            text-align: center;
            font-size: 13px;
            color: #00101A;
            &:focus, &:hover {
                border: 1px solid #99CC33;
            }
            &:disabled {
                background-color: #F3F3F3;
                border: 0.5px solid #ddd;
            }
        }
    }
`;